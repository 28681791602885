import React from 'react';

import { Link } from 'gatsby';

import PostThumbnail from './PostThumbnail';

const Post = ({ post }) => (
  <>
    <Link to={ post.fields.slug } className={ `teaser teaser--${post.data.Category.toLowerCase()}` }>
      <div className="teaser__metadata">
        <div>{ post.data.Title }</div>
      </div>
      <PostThumbnail thumbnail={ post.data.Thumbnail } />
    </Link>
  </>
);

const PostListAuthor = ({ posts }) => (
  <div className="list">
    { posts.map(({ node: post }, key) => (
      <div key={ `item-${key * key}` } className="list__item">
        <Post post={ post } />
      </div>
    )) }
  </div>
);

export default PostListAuthor;
