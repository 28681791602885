/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostListAuthor from '../components/posts/ListAuthor';

const Author = ({ data }) => {
  const author = data.author.data;
  const posts = data.posts.edges;

  return (
    <Layout pageClass="page--full">
      <h1 className="page__title">{ author.Name }</h1>
      <PostListAuthor posts={ posts } />
    </Layout>
  );
};

export default Author;

export const query = graphql`
  query($id: String!, $name: String!) {
    author: airtable(id: { eq: $id }) {
      id
      data {
        Name
      }
    }
    posts: allAirtable(filter: {table: {eq: "Posts"}, data: {Author: {elemMatch: {data: {Name: {eq: $name}}}}}}) {
      edges {
        node {
          id
          data {
            Title
            Category
            Author {
              data {
                Name
                Picture {
                  localFiles {
                    childImageSharp {
                      fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                Passion
                Specialism
              }
            }
            Date(formatString: "DD.MM.YYYY")
            Thumbnail {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
